import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import MediaQuery from "react-responsive"

import "../Layout.css"

const Cafe = () => {
    
  const contentfulData = useStaticQuery(graphql`
    query CafeQuery {
      allContentfulHomeCafe {
        edges {
          node {
            caption1 {
              caption1
            }
            caption2 {
              caption2
            }
            captionColor
            captionFontFamily
            captionFontSize
            image {
              file {
                url
              }
            }
            imageAltText
            headerTitleLogo {
              file {
                url
              }
            }
            headerTitleLogoBlack {
              file {
                url
              }
            }
            mobileImage {
              file {
                url
              }
            }
            tabletImage {
              file {
                url
              }
            }            
            titleColor
            titleFontFamily
            titleFontSize
            titleFontWeight
            titleText
          }
        }
      }
    }
  `)
  return(
    <div>
      <MediaQuery minWidth={1024}>
        <div className="cafe-container-size">
        <div style={{ height:"100%", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize:"cover", backgroundImage: `url(${contentfulData.allContentfulHomeCafe.edges[0].node.image.file.url})` }}>
        <div name="Cafe" className="cafe-container-text">
          <img
            src={contentfulData.allContentfulHomeCafe.edges[0].node.headerTitleLogo.file.url} 
          />
          <p style={{ 
            fontFamily: contentfulData.allContentfulHomeCafe.edges[0].node.captionFontFamily,
            fontSize: contentfulData.allContentfulHomeCafe.edges[0].node.captionFontSize,
            color: contentfulData.allContentfulHomeCafe.edges[0].node.captionColor}}
            >
            {contentfulData.allContentfulHomeCafe.edges[0].node.caption1.caption1}
          </p>
          <p style={{ 
            fontFamily: contentfulData.allContentfulHomeCafe.edges[0].node.captionFontFamily,
            fontSize: contentfulData.allContentfulHomeCafe.edges[0].node.captionFontSize,
            color: contentfulData.allContentfulHomeCafe.edges[0].node.captionColor}}
            >
            {contentfulData.allContentfulHomeCafe.edges[0].node.caption2.caption2}
          </p>
        </div>
        </div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <div className="mobile-cafe cafe-container-size" style={{ backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize:"100% 100%", backgroundImage: `url(${contentfulData.allContentfulHomeCafe.edges[0].node.tabletImage.file.url})` }}>
            <div name="Cafe" className="cafe-mobile-sizing cafe-container-text">
            <img
                src={contentfulData.allContentfulHomeCafe.edges[0].node.headerTitleLogo.file.url} 
                />
            <p style={{ 
              fontFamily: "Roboto",
              fontSize: "16px",
              margin:"5%",
              marginTop: "1%",
              color: "white"}}
              >
              {contentfulData.allContentfulHomeCafe.edges[0].node.caption1.caption1}
            </p>
            <p style={{ 
              fontFamily: "Roboto",
              fontSize: "16px",
              margin:"5%",
              color: "white"}}
              >
              {contentfulData.allContentfulHomeCafe.edges[0].node.caption2.caption2}
            </p>
          </div>
        </div>
      </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="cafe-container-size" style={{ backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize:"100% 100%", backgroundImage: `url(${contentfulData.allContentfulHomeCafe.edges[0].node.mobileImage.file.url})` }}>
            <div name="Cafe" className="cafe-mobile-sizing" style={{textAlign:"center"}}>
              <img
                src={contentfulData.allContentfulHomeCafe.edges[0].node.headerTitleLogoBlack.file.url}
                width="60%"
                style={{marginBottom:"1%", marginTop: "3%"}}
                />
            <p style={{ 
              fontFamily: "Roboto",
              fontSize: "16px",
              margin:"5%",
              textAlign:"left",
              marginTop: "1%",
              color: "black"}}
              >
              {contentfulData.allContentfulHomeCafe.edges[0].node.caption1.caption1}
            </p>
            <p style={{ 
              fontFamily: "Roboto",
              fontSize: "16px",
              margin:"5%",
              textAlign:"left",
              color: "black"}}
              >
              {contentfulData.allContentfulHomeCafe.edges[0].node.caption2.caption2}
            </p>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

export default Cafe