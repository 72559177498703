import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Container, Col, Row, Button, ButtonToolbar, InputGroup, FormControl } from "react-bootstrap"
import MediaQuery from "react-responsive"

import "../Layout.css"

const Footer = () => {
    
    const contentfulData = useStaticQuery(graphql`
    query MyQuery {
        allContentfulHomeContact {
          edges {
            node {
              address
              addressColor
              addressFontFamily
              addressFontSize
              addressFontWeight
              backgroundColor
              button1Text
              button2Text
              caption
              captionColor
              captionFontFamily
              captionFontSize
              captionFontWeight
              formLabelColor
              formLabelFontFamily
              formLabelFontSize
              formLabelFontWeight
              leftTitleText
              rightTitleText
              titleColor
              titleFontFamily
              titleFontSize
              titleFontWeight
            }
          }
        }
      }
    `)
    const footerData = contentfulData.allContentfulHomeContact.edges[0].node
    return (
        <Container name="Contact" fluid style={{height:"15%", backgroundColor: footerData.backgroundColor, paddingTop:"20px", paddingBottom:"20px"}}>
        <MediaQuery minWidth={768}>
            <Row style={{ height: "100%", color: footerData.titleColor, paddingLeft:"5%", paddingRight:"5%" }}>
                <Col xl={3} lg={3} md={3}  style={{marginLeft:"30px", margin: "1%"}}>
                    <p style={{
                        color: footerData.addressColor,
                        fontFamily: "Noto Serif",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        textTransform: "uppercase"}} 
                    >
                    {footerData.leftTitleText}
                    </p>
                    <p style={{
                        color: footerData.addressColor,
                        fontFamily: footerData.addressFontFamily,
                        fontSize: "18px",
                        fontWeight: footerData.addressFontFamily}}
                        >
                        1444 3rd Street Promenade
                        <br />
                        Santa Monica, CA 90401
                    </p>
                    <ButtonToolbar>
                        <Button href="/contact" style={{ position: "absolute", right:"0px", bottom:"0px", marginBottom:"unset", width:"100%", border: "1px black solid"}} className="custom-light-button">{footerData.button2Text}</Button>
                    </ButtonToolbar>
                </Col>
                <Col xl={3} lg={3} sm={3} md={3} style={{ maxWdith: "100%", maxHeight: "100%" }}>
                    <Container fluid className="map-container">
                        <iframe title="googleMapsAddress" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.1435794829376!2d-118.49755424893958!3d34.014525280521084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2a4d03299816d%3A0xfa81378059739916!2s1444%203rd%20Street%20Promenade%2C%20Santa%20Monica%2C%20CA%2090401%2C%20USA!5e0!3m2!1sen!2sca!4v1578419709712!5m2!1sen!2sca" width="100%" height="100%" frameborder="0" style={{border:"0px"}} allowfullscreen=""></iframe>
                    </Container>
                </Col>
                <Col xl={5} lg={5} md={5} style={{ float:"right", margin:"1%" }} >
                    <p style={{
                        color: footerData.addressColor,
                        fontFamily: "Noto Serif",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        marginBottom: "5px!important"}}
                        >
                        {footerData.rightTitleText}
                    </p>
                    <p style={{
                        color: footerData.captionColor,
                        fontFamily: footerData.captionFontFamily,
                        fontSize: "18px",
                        fontWeight: footerData.captionFontFamily,
                    }}
                    >
                        {footerData.caption}
                    </p>
                    <Row style={{color: footerData.formLabelColor}}>
                        <Col xl={4} lg={4} sm={4} md={4}>
                            <label htmlFor="email">Email</label>
                            <InputGroup>
                                <FormControl
                                    id="email"
                                />
                            </InputGroup>
                        </Col>
                        <Col xl={4} lg={4} sm={4} md={4}>
                            <label style={{fontColor: footerData.formLabelColor}} htmlFor="phone">Phone(Optional)</label>
                            <InputGroup>
                                <FormControl
                                    id="phone"
                                />
                            </InputGroup>
                        </Col>
                        <Col xl={4} lg={4} sm={4} md={4} style={{ marginTop:"10px"}}>
                            <Button style={{ position:"absolute", bottom:"0px" }}>Submit</Button>
                        </Col>
                    </Row>              
                </Col>
            </Row>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
            <Row style={{ height: "100%", color: footerData.titleColor }}>
                <Col xs={5} sm={5} md={5}  style={{ maxHeight:"100px", margin:"10px", marginBottom:"50px"}}>
                    <p style={{
                        color: footerData.captionColor,
                        fontFamily: 'Noto Serif',
                        fontSize: "24px",
                        fontWeight: footerData.captionFontFamily,
                        textTransform: "uppercase"
                    }}
                    >
                    {footerData.leftTitleText}
                    </p>
                    <p style={{
                        color: footerData.addressColor,
                        fontFamily: footerData.addressFontFamily,
                        fontSize: "16px",
                        fontWeight: footerData.addressFontFamily}}
                        >
                        {footerData.address}
                    </p>
                </Col>
                <Col xs={5} sm={5} md={5} style={{  maxHeight:"100px", margin:"5px" }}>
                    <Container fluid className="map-container unset-side-padding">
                        <iframe title="googleMapsAddress" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.1435794829376!2d-118.49755424893958!3d34.014525280521084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2a4d03299816d%3A0xfa81378059739916!2s1444%203rd%20Street%20Promenade%2C%20Santa%20Monica%2C%20CA%2090401%2C%20USA!5e0!3m2!1sen!2sca!4v1578419709712!5m2!1sen!2sca" width="100%" height="90px" frameBorder="0" style={{ border:"2px black solid" }} allowFullScreen=""></iframe>
                    </Container>
                </Col>
                <Button style={{ width:"100%", margin:"30px", border:"2px black solid"}} className="custom-light-button">{footerData.button2Text}</Button>
                <Col xs={12} sm={12} md={12} style={{margin:"15px", marginLeft: "unset", marginRight:"unset"}}>
                    <p style={{
                        color: footerData.captionColor,
                        fontFamily: 'Noto Serif',
                        fontSize: "24px",
                        fontWeight: footerData.captionFontFamily,
                        textTransform: "uppercase"
                    }}
                    >
                        {footerData.rightTitleText}
                    </p>
                    <p style={{
                        color: footerData.captionColor,
                        fontFamily: footerData.captionFontFamily,
                        fontSize: "16px",
                        fontWeight: footerData.captionFontFamily}}
                    >
                        {footerData.caption}
                    </p>
                    <Row style={{margin:"5px", color: footerData.formLabelColor}}>
                        <Col sm={12} xs={12} className="unset-side-padding">
                            <label style={{fontColor: footerData.formLabelColor}} htmlFor="email">Email</label>
                            <InputGroup className="mb-3" style={{maxWidth:"100%"}}>
                                <FormControl
                                    style={{backgroundColor:"#545389", border:"1px white solid"}}
                                    id="email"
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={12} xs={12} className="unset-side-padding">
                            <label style={{fontColor: footerData.formLabelColor, float:"left"}} htmlFor="phone">Phone(Optional)</label>
                            <InputGroup className="mb-3" style={{maxWidth:"100%"}}>
                                <FormControl
                                    style={{backgroundColor:"#545389", border:"1px white solid"}}
                                    id="phone"
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={12} xs={12} style={{textAlign:"center"}} className="unset-side-padding">
                            <Button className="custom-light-button" style={{bottom:"0px", width:"100%", border:"2px black solid"}}>Submit</Button>
                        </Col>
                    </Row>              
                </Col>
            </Row>
            </MediaQuery>
        </Container>
    )
}

export default Footer