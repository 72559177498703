import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Image, Row, Col, Container } from "react-bootstrap";
import MediaQuery from "react-responsive"

import "../Layout.css"

const About = () => {
  const contentfulData = useStaticQuery(graphql`
    query AboutHome {
      allContentfulNavigationBar {
        nodes {
          logoImage {
            file {
              url
            }
          }
        }
      }
          
      allContentfulHomeAbout {
        edges {
          node {
            attraction1Caption
            attraction1Image {
              file {
                url
              }
            }
            attraction1ImageAltText
            attraction1TitleText
            attraction2Caption
            attraction2Image {
              file {
                url
              }
            }
            attraction2ImageAltText
            attraction2TitleText
            attractionCaptionColor
            attractionCaptionFontFamily
            attractionCaptionFontSize
            attractionCaptionFontWeight
            attractionTitleColor
            attractionTitleFontSize
            attractionTitleFontFamily
            attractionTitleFontWeight
            caption1 {
              caption1
            }
            caption2 {
              caption2
            }
            captionColor
            captionFontFamily
            captionFontSize
            captionFontWeight
            imageAltText
            image {
              file {
                url
              }
            }
            titleColor
            titleFontFamily
            titleFontSize
            titleFontWeight
            titleText
          }
        }
      }
    }
  `)

  return(
    <div>
      <MediaQuery minWidth={1024}>
        <Container name="About" fluid className="container-size" style={{backgroundColor: "black" }}>
          <Row style={{textAlign: "center"}} className="container-size">
            <Col xl={6} lg={6} sm={6} md={6} xs={6} className="unset-side-padding">
              <Image
                src={contentfulData.allContentfulHomeAbout.edges[0].node.image.file.url}
                width="100%"
                className="container-size"
                alt={contentfulData.allContentfulHomeAbout.edges[0].node.imageAltText}
                style={{marginBottom: "0px", objectFit: "cover" }}/>
            </Col>
            <Col xl={6} lg={6} sm={6} md={6} xs={6} style={{top:"50px"}}>
              <Row>
                <Col xl={12} lg={12} sm={12} md={12} xs={12}>
                    <p style={{ 
                      fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontWeight, 
                      fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontFamily, 
                      fontSize: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontSize, 
                      color: "rgb(109, 108, 164)",
                      textTransform: 'uppercase' }}
                      >
                      What is 
                       <span>
                        <img 
                            src={contentfulData.allContentfulNavigationBar.nodes[0].logoImage.file.url}
                            width="250"
                            alt={contentfulData.allContentfulNavigationBar.nodes[0].logoImageAltText}
                            style={{marginBottom: "0px", margin:"5px"}}/>
                      </span>
                    </p>
                </Col>
                <Col xl={12} lg={12} sm={12} md={12} xs={12}>
                  <p style={{ 
                    fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontWeight, 
                    fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontFamily, 
                    fontSize: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontSize, 
                    color: contentfulData.allContentfulHomeAbout.edges[0].node.captionColor }}
                  >
                    {contentfulData.allContentfulHomeAbout.edges[0].node.caption1.caption1}
                  </p>
                </Col>
                </Row>
                <Row style={{marginBottom:"5%"}}>
                  <Col xl={12} lg={12} sm={12} md={12} xs={12}>
                    <p style={{ 
                      fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontWeight, 
                      fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontFamily, 
                      fontSize: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontSize, 
                      color: contentfulData.allContentfulHomeAbout.edges[0].node.captionColor }}
                    >
                      {contentfulData.allContentfulHomeAbout.edges[0].node.caption2.caption2}
                    </p>
                  </Col>
              </Row>
              <Row style={{justifyContent:"center"}}>
                  <figure className="current-product-left-figure"> 
                    <img
                      src={contentfulData.allContentfulHomeAbout.edges[0].node.attraction1Image.file.url}
                      width="100%"
                      height="auto"
                      alt={contentfulData.allContentfulHomeAbout.edges[0].node.attraction1ImageAltText}
                      style={{ objectFit: "cover", marginBottom:"2%" }}/>
                      <p style={{
                        marginBottom: "unset",
                        fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontWeight, 
                        fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontFamily, 
                        fontSize: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontSize, 
                        color: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleColor}}
                      >
                      {contentfulData.allContentfulHomeAbout.edges[0].node.attraction1TitleText}
                    </p>
                  </figure>
                  <figure className="current-product-right-figure">
                    <img
                      src={contentfulData.allContentfulHomeAbout.edges[0].node.attraction2Image.file.url}
                      width="100%"
                      height="auto"
                      alt={contentfulData.allContentfulHomeAbout.edges[0].node.attraction2ImageAltText}
                      style={{ objectFit: "cover", marginBottom:"2%" }}/>
                      <p style={{
                        marginBottom: "unset",
                        fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontWeight, 
                        fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontFamily, 
                        fontSize: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleFontSize, 
                        color: contentfulData.allContentfulHomeAbout.edges[0].node.attractionTitleColor}}
                        >
                        {contentfulData.allContentfulHomeAbout.edges[0].node.attraction2TitleText}
                      </p>  
                    </figure>                
              </Row>
          </Col>
        </Row>
        </Container>
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <Container name="About" fluid className="about-container-size unset-side-padding" style={{ backgroundColor:"black"  }}>
            <Col sm={12} xs={12} className="unset-side-padding" style={{ paddingBottom: "5%" }}>
              <figure style={{textAlign:"center"}}>
                <Image
                  src={contentfulData.allContentfulHomeAbout.edges[0].node.image.file.url}
                  width="100%"
                  alt={contentfulData.allContentfulHomeAbout.edges[0].node.imageAltText}
                  style={{marginBottom: "0px", objectFit: "cover", height:"45vh", marginBottom:"2%" }}/>
                  <MediaQuery maxWidth={766}>
                   <p style={{ 
                      fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontWeight, 
                      fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontFamily, 
                      fontSize: "24px", 
                      color: "rgb(109, 108, 164)",
                      textTransform: 'uppercase' }}
                      >
                      What is 
                       <span>
                          <img 
                            src={contentfulData.allContentfulNavigationBar.nodes[0].logoImage.file.url}
                            width="170"
                            alt={contentfulData.allContentfulNavigationBar.nodes[0].logoImageAltText}
                            style={{marginBottom: "0px", margin:"5px"}}/>
                        </span>
                      </p>
                    </MediaQuery>
                    <MediaQuery minWidth={767}>
                      <p style={{ 
                        fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontWeight, 
                        fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.titleFontFamily, 
                        fontSize: "32px", 
                        color: "rgb(109, 108, 164)",
                        textTransform: 'uppercase' }}
                        >
                        What is 
                        <span>
                          <img 
                            src={contentfulData.allContentfulNavigationBar.nodes[0].logoImage.file.url}
                            width="250"
                            alt={contentfulData.allContentfulNavigationBar.nodes[0].logoImageAltText}
                            style={{marginBottom: "0px", margin:"5px"}}/>
                      </span>
                    </p>
                    </MediaQuery>
                  <p style={{ 
                    fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontWeight, 
                    fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontFamily, 
                    fontSize: "16px",
                    textAlign: "left", 
                    marginLeft: "5%",
                    marginRight: "5%",
                    color: contentfulData.allContentfulHomeAbout.edges[0].node.captionColor }}
                  >
                    {contentfulData.allContentfulHomeAbout.edges[0].node.caption1.caption1}
                  </p>
                  <p style={{ 
                      fontWeight: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontWeight, 
                      fontFamily: contentfulData.allContentfulHomeAbout.edges[0].node.captionFontFamily, 
                      fontSize: "16px",
                      textAlign: "left",
                      marginLeft: "5%",
                      marginRight: "5%", 
                      color: contentfulData.allContentfulHomeAbout.edges[0].node.captionColor,
                       }}
                    >
                      {contentfulData.allContentfulHomeAbout.edges[0].node.caption2.caption2}
                    </p>
                    </figure>
                </Col>
          </Container>
      </MediaQuery>
      </div>
  )
}

export default About