import styled from "styled-components";
import { Row } from "react-bootstrap";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${props => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${props => {
    if (!props.sliding) return "translateX(calc(-80% - 20px))";
    if (props.dir === PREV) return "translateX(calc(2 * (-80% - 20px)))";
    return "translateX(0%)";
  }};
`;

export const Item = styled.div`
  text-align: center;
  top: 50%;
`;

export const CustomRow = styled(Row)`
display: flex;
flex-wrap: wrap;
overflow: visible;
margin-left: unset!important;
`

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 85%;
  margin-right: 10px;
  margin-left: 10px;
  background-color: grey;
  order: ${props => props.order};
`;
