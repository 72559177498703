import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import MediaQuery from "react-responsive"
import { Item, CustomRow } from "../Layout/Carousel/Components"
import Carousel from "../Layout/Carousel/Carousel"

import "../Layout.css"

const CurrentProducts = () => {

  const contentfulData = useStaticQuery(graphql`
    query HomeAttractions {
      allContentfulHomeAttractionsBackground {
        nodes {
          homeAttractionsBackground {
            file {
              url
            }
          }
        }
      }
      allContentfulHomeAttractionsHeading {
        edges {
          node {
            headingColor
            headingFontFamily
            headingFontSize
            headingText
          }
        }
      }
      allContentfulHomeAttraction(sort: {fields: rowLocation, order: ASC}) {
        edges {
          node {
            caption {
              caption
            }
            captionColor
            captionFontFamily
            captionFontSize
            imageAltText
            image {
              file {
                url
              }
            }
            titleColor
            titleFontFamily
            titleFontSize
            titleText
          }
        }
      }
    }
  `)
  return (
    <Container fluid style={{ backgroundImage: `url(${contentfulData.allContentfulHomeAttractionsBackground.nodes[0].homeAttractionsBackground.file.url})`}} className="container-current-products-size margin-current-products">
      <MediaQuery minWidth={1024}>
      <Row style={{textAlign: "center"}}>
        <Col>
          <p style={{
            color: contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingColor,
            margin: "30px",
            fontSize: contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingFontSize,
            textTransform: "uppercase",
            fontFamily: "Noto Serif"}}
            >
            {contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingText}
          </p>
        </Col>
      </Row>
      <Row className="div-title" style={{textAlign: "center", marginRight: "5%", marginLeft: "5%"}}>
      {
        contentfulData.allContentfulHomeAttraction.edges.map((data) =>
          <Col className="unset-side-padding" xl={4} lg={4} md={4}>
            <img
              src={data.node.image.file.url}
              width="80%"
              alt={data.node.imageAltText}
              style={{marginBottom: "1%"}}/>
              <p style={{marginBottom: "unset", color: data.node.titleColor, fontFamily: data.node.titleFontFamily, fontSize: data.node.titleFontSize }}>{data.node.titleText}</p>
              <p style={{maxWidth:"75%", display:"inline-block", textTransform:"none", color: data.node.captionColor, fontFamily: data.node.captionFontFamily, fontSize: data.node.captionFontSize }}>{data.node.caption.caption}</p>
          </Col>
      )}
    </Row>
    </MediaQuery>
    <MediaQuery maxWidth={1023}>
      <Row style={{textAlign: "center", marginLeft:"unset", marginRight:"unset"}}>
        <Col>
          <p 
            style={{
            color: contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingColor,
            margin: "10px",
            fontSize: "4.5vw",
            textTransform: "uppercase",
            fontFamily: contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingFontFamily}}
            >
            {contentfulData.allContentfulHomeAttractionsHeading.edges[0].node.headingText}
          </p>
        </Col>
      </Row>
      <CustomRow>
        <Carousel style={{backgroundColor:"grey"}}>
          {
            contentfulData.allContentfulHomeAttraction.edges.map((data) => 
          <Item>
            <figure>
            <img
              src={data.node.image.file.url}
              alt={data.node.imageAltText}
              className="mobile-swiper-image-width"
            />
              <p style={{ marginBottom: "unset", fontWeight: "bold", fontFamily: data.node.titleFontFamily, fontSize: "20px", color: data.node.titleColor }}>{data.node.titleText}</p>
              <p style={{ maxWidth:"90%", display:"inline-block", fontWeight: "regular", fontFamily: data.node.captionFontFamily, fontSize: "16px", color: data.node.captionColor}}>{data.node.caption.caption}</p>
              </figure>
          </Item>
            )
          }
        </Carousel>
        </CustomRow>
    </MediaQuery>
  </Container>
  )
}

export default CurrentProducts