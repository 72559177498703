/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Video from "./Video"
import Contact from "./Contact"
import About from "./About"
import CurrentProducts from "./CurrentProducts"
import Cafe from "./Cafe"
import "../Layout.css"

const HomePage = () => {
  
  return (
      <div>
        <Video />
        <CurrentProducts />
        <Cafe name="Cafe"/>  
        <div className="about-container-size">
          <About />
        </div>
        <Contact name="Contact"/>
      </div>
  )
}

export default HomePage
