import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo"
import Home from "../components/Homepage/Homepage"


import 'bootstrap/dist/css/bootstrap.min.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
  </Layout>
)

export default IndexPage
