import React from "react"
import { useStaticQuery, graphql } from "gatsby"


const Video = () => {
    const contentfulData = useStaticQuery(graphql`
    query BannerVideo {
        allContentfulHomeHeroBanner {
          edges {
            node {
              heroBanner {
                file {
                  url
                }
              }
            }
          }
        }
      }
  `)
  return(
    <div>
      <video src={contentfulData.allContentfulHomeHeroBanner.edges[0].node.heroBanner.file.url} height="100%" playsinline playsInline autoPlay muted loop style={{objectFit: "contain", width:"100%"}}/>
    </div>
    )
}

export default Video